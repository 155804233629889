import React from 'react'
import CreateCartDrawer from './createCartDrawer'

const getIDFromPathname = pathname =>
  pathname
    .split('/')
    .pop()

function CartDrawerOfferItem(props) {
  const id = getIDFromPathname(props.location.pathname)
  return <CreateCartDrawer {...props} id={id} />
}

export default CartDrawerOfferItem;